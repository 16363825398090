import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./testimonial-slider.scss";

export default class TestimonialSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 300000,
    };
    return (
        <div className="testimonial-wrapper">
            <Slider {...settings}>
                
                <div className="testimonial-item">
                    <i className="bi bi-quote icon"></i>
                    <div className="testimonial-content">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis fugit delectus voluptates facere sint saepe eius qui odit ex nisi ducimus officia optio porro, architecto voluptatibus accusamus. Quia, nostrum autem.</p>
                    </div>
                    <div className="user-detail">
                        <div className="img-thumb" style={{ backgroundImage: "url('./../images/image-2.jpg')", backgroundPosition: "50% 50%" }}>
                            {/* <img src="./../images/image-2.jpg" alt="testimonial thumb" /> */}
                        </div>
                        <div className="user-name">
                            john doe
                        </div>
                        <div className="user-designation">
                            ( Student )
                        </div>
                    </div>
                </div>
                <div className="testimonial-item">
                    <i className="bi bi-quote icon"></i>
                    <div className="testimonial-content">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis fugit delectus voluptates facere sint saepe eius qui odit ex nisi ducimus officia optio porro, architecto voluptatibus accusamus. Quia, nostrum autem.</p>
                    </div>
                    <div className="user-detail">
                        <div className="img-thumb" style={{ backgroundImage: "url('./../images/image-2.jpg')", backgroundPosition: "50% 50%" }}>
                            {/* <img src="./../images/image-2.jpg" alt="testimonial thumb" /> */}
                        </div>
                        <div className="user-name">
                            john doe
                        </div>
                        <div className="user-designation">
                            ( Student )
                        </div>
                    </div>
                </div>
                <div className="testimonial-item">
                    <i className="bi bi-quote icon"></i>
                    <div className="testimonial-content">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis fugit delectus voluptates facere sint saepe eius qui odit ex nisi ducimus officia optio porro, architecto voluptatibus accusamus. Quia, nostrum autem.</p>
                    </div>
                    <div className="user-detail">
                        <div className="img-thumb" style={{ backgroundImage: "url('./../images/image-2.jpg')", backgroundPosition: "50% 50%" }}>
                            {/* <img src="./../images/image-2.jpg" alt="testimonial thumb" /> */}
                        </div>
                        <div className="user-name">
                            john doe
                        </div>
                        <div className="user-designation">
                            ( Student )
                        </div>
                    </div>
                </div>
                <div className="testimonial-item">
                    <i className="bi bi-quote icon"></i>
                    <div className="testimonial-content">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis fugit delectus voluptates facere sint saepe eius qui odit ex nisi ducimus officia optio porro, architecto voluptatibus accusamus. Quia, nostrum autem.</p>
                    </div>
                    <div className="user-detail">
                        <div className="img-thumb" style={{ backgroundImage: "url('./../images/image-2.jpg')", backgroundPosition: "50% 50%" }}>
                            {/* <img src="./../images/image-2.jpg" alt="testimonial thumb" /> */}
                        </div>
                        <div className="user-name">
                            john doe
                        </div>
                        <div className="user-designation">
                            ( Student )
                        </div>
                    </div>
                </div>
                <div className="testimonial-item">
                    <i className="bi bi-quote icon"></i>
                    <div className="testimonial-content">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis fugit delectus voluptates facere sint saepe eius qui odit ex nisi ducimus officia optio porro, architecto voluptatibus accusamus. Quia, nostrum autem.</p>
                    </div>
                    <div className="user-detail">
                        <div className="img-thumb" style={{ backgroundImage: "url('./../images/image-2.jpg')", backgroundPosition: "50% 50%" }}>
                            {/* <img src="./../images/image-2.jpg" alt="testimonial thumb" /> */}
                        </div>
                        <div className="user-name">
                            john doe
                        </div>
                        <div className="user-designation">
                            ( Student )
                        </div>
                    </div>
                </div>
                <div className="testimonial-item">
                    <i className="bi bi-quote icon"></i>
                    <div className="testimonial-content">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis fugit delectus voluptates facere sint saepe eius qui odit ex nisi ducimus officia optio porro, architecto voluptatibus accusamus. Quia, nostrum autem.</p>
                    </div>
                    <div className="user-detail">
                        <div className="img-thumb" style={{ backgroundImage: "url('./../images/image-2.jpg')", backgroundPosition: "50% 50%" }}>
                            {/* <img src="./../images/image-2.jpg" alt="testimonial thumb" /> */}
                        </div>
                        <div className="user-name">
                            john doe
                        </div>
                        <div className="user-designation">
                            ( Student )
                        </div>
                    </div>
                </div>
                

            </Slider>
        </div>
    );
  }
}
